import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { find, head, last, noop } from 'lodash/fp';
import PropTypes from 'prop-types';
import { Component, createContext, createElement, createRef } from 'react';
import { AriaLabel } from '../../components/AriaHelper';
import * as keyEventStackService from '../../services/KeyEventStack';
import { getNext, getPrevious } from '../../services/List';
import { scrollIntoViewIfNeeded } from '../../services/Scroll';
import Button from '../Button';
import { findMatch } from './textMatcher';
// Beware!
// Keyboard handling does not work on Safari.
const WRAPPER_STYLE = { position: 'relative' };
const HIDDEN_STYLE = { display: 'none' };
const NO_STYLE = {};
const FULL_WIDTH = { width: '100%' };
const isAlphabetic = key => /^[a-z]$/i.test(key);
const SelectContext = createContext();
const propTypes = {
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    selectedClassName: PropTypes.string,
    highlightedClassName: PropTypes.string,
    selectComponent: PropTypes.any, // component
    menuComponent: PropTypes.any, // component
    placeHolder: PropTypes.any, // component
    optionToText: PropTypes.func,
    hasError: PropTypes.bool,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    ariaDescription: PropTypes.string,
    optionToAriaLabel: PropTypes.func,
    selected: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    dataIntercomTarget: PropTypes.string,
};
const defaultProps = {
    menuComponent: 'div',
    placeholder: () => _jsx("span", { children: "--" }),
    optionToText: null,
    optionToAriaLabel: () => '',
    hasError: false,
    onBlur: noop,
    ariaLabel: 'Options',
    ariaDescription: 'Use arrow keys to select options',
};
let counter = 0;
const generateId = () => `react-select-instance-${counter++}`;
class Select extends Component {
    constructor() {
        super();
        this.openIfNotOpen = () => {
            if (!this.state.isOpen) {
                this.toggle(true);
            }
        };
        this.selectAndClose = option => {
            this.props.onSelect(option);
            this.toggle(false);
            this.buttonRef.current.focus();
        };
        this.getAriaLabel = option => {
            if (!option)
                return;
            return (this.props.optionToText || this.props.optionToAriaLabel)(option);
        };
        this.onFocus = () => {
            // this is a refocus event, trigger internally by us
            if (this.state.isFocused) {
                return;
            }
            keyEventStackService.register(this.focusKeyEventStackConfig);
        };
        this.onBlur = () => {
            if (this.skipBlur) {
                this.skipBlur = false;
                // need to set a timeout here for firefox - this blur event seems to bubble
                // in a strange way there, which makes called button.focus synchronously ineffective,
                // as the bubbling event will override the focus event and set focus on the document body.
                setTimeout(() => this.buttonRef.current && this.buttonRef.current.focus());
                return;
            }
            this.setState({ isFocused: false, isOpen: false });
            keyEventStackService.deregister(this.openKeyEventStackConfig);
            keyEventStackService.deregister(this.focusKeyEventStackConfig);
            this.props.onBlur();
        };
        this.onClick = event => {
            event.stopPropagation();
            event.preventDefault();
            if (this.props.disabled) {
                return;
            }
            if (this.state.isFocused && !this.justChanged) {
                this.toggle(!this.state.isOpen);
            }
            else {
                this.toggle(true);
            }
        };
        this.onHighlight = option => {
            this.setState(prevState => (Object.assign(Object.assign({}, prevState), { highlighted: option })));
        };
        this.state = {
            isOpen: false,
            isFocused: false,
            highlighted: undefined,
        };
        const id = generateId();
        this.ids = {
            menu: `${id}-menu`,
            selected: `${id}-selected`,
            label: `${id}-label`,
            description: `${id}-description`,
        };
        this.options = [];
        this.optionsAsText = [];
        this.keySequence = '';
        this.keySequenceTimeout = null;
        this.focusKeyEventStackConfig = {
            onKey: ev => this.handleKeyEventInFocusState(ev),
        };
        this.openKeyEventStackConfig = {
            onKey: ev => this.handleKeyEventInOpenState(ev),
        };
        this.buttonRef = createRef();
    }
    componentWillUnmount() {
        keyEventStackService.deregister(this.focusKeyEventStackConfig);
        keyEventStackService.deregister(this.openKeyEventStackConfig);
        this.clearKeySequenceTimeout();
    }
    clearKeySequenceTimeout() {
        clearTimeout(this.keySequenceTimeout);
        this.keySequenceTimeout = null;
    }
    handleKeyEventInFocusState(event) {
        const actions = {
            withAltKey: {
                ArrowUp: () => this.selectFirst(),
                ArrowDown: () => this.selectLast(),
                ' ': () => this.toggle(true),
            },
            regular: {
                ArrowUp: () => this.selectPrevious(),
                ArrowDown: () => this.selectNext(),
                ' ': () => this.toggle(true),
            },
        };
        const { key, altKey } = event;
        const modifier = altKey ? 'withAltKey' : 'regular';
        if (actions[modifier][key]) {
            actions[modifier][key]();
            return keyEventStackService.stopEvent(event);
        }
        if (key === 'Tab') {
            this.toggle(false); // bubble the event!
        }
    }
    handleKeyEventInOpenState(event) {
        const actions = {
            Enter: () => this.selectCurrent(),
            ' ': () => this.selectCurrent(),
            Escape: () => this.toggle(false),
        };
        const { key } = event;
        if (actions[key]) {
            actions[key]();
            return keyEventStackService.stopEvent(event);
        }
        if (isAlphabetic(key)) {
            this.clearKeySequenceTimeout();
            const i = this.options.indexOf(this.props.selected);
            const m = findMatch(this.keySequence + key, this.optionsAsText, this.optionsAsText[i]);
            if (m.match) {
                const { match, sequence } = m;
                const nextI = this.optionsAsText.indexOf(match);
                this.props.onSelect(this.options[nextI]);
                this.keySequence = sequence;
                this.keySequenceTimeout = setTimeout(() => {
                    this.keySequence = '';
                    this.clearKeySequenceTimeout();
                }, 2000);
            }
            else {
                this.keySequence = '';
            }
            return keyEventStackService.stopEvent(event);
        }
    }
    ifOptions(cb) {
        if (this.options.length) {
            cb();
        }
    }
    getNext(option) {
        return getNext(this.options, option, { wrap: false });
    }
    getPrevious(option) {
        return getPrevious(this.options, option, { wrap: false });
    }
    getFirst() {
        return head(this.options);
    }
    getLast() {
        return last(this.options);
    }
    selectNext() {
        this.openIfNotOpen();
        this.ifOptions(() => {
            this.onHighlight(undefined);
            this.props.onSelect(this.getNext(this.props.selected));
        });
    }
    selectPrevious() {
        this.openIfNotOpen();
        this.ifOptions(() => {
            this.onHighlight(undefined);
            this.props.onSelect(this.getPrevious(this.props.selected));
        });
    }
    selectFirst() {
        this.openIfNotOpen();
        this.ifOptions(() => {
            this.onHighlight(undefined);
            this.props.onSelect(this.getFirst());
        });
    }
    selectLast() {
        this.openIfNotOpen();
        this.ifOptions(() => {
            this.onHighlight(undefined);
            this.props.onSelect(this.getLast());
        });
    }
    selectCurrent() {
        this.ifOptions(() => {
            if (this.state.highlighted) {
                this.selectAndClose(this.state.highlighted);
            }
            else {
                this.toggle(false);
            }
        });
    }
    toggle(isOpen) {
        this.setState(prevState => {
            if (isOpen) {
                if (document.activeElement !== this.buttonRef.current) {
                    this.buttonRef.current.focus();
                }
                keyEventStackService.register(this.openKeyEventStackConfig);
            }
            else {
                keyEventStackService.deregister(this.openKeyEventStackConfig);
            }
            return Object.assign(Object.assign({}, prevState), { isOpen, highlighted: undefined, isFocused: isOpen ? true : prevState.isFocused });
        });
        // This is a dirty hack to make this work across browsers
        // The underlying problem is that the relation between focus
        // and click is not the same in e.g. Chrome and Safari.
        // A click on a button leads to a focus event in Chrome,
        // but not in Safari.
        // A select element does receive focus on a click event however,
        // and we absolutely want to simulate this.
        // We need to go through some hoops here to make this work and
        // the hack down below is necessary to prevent events from
        // being registered twice in Chrome (because there a focus and
        // a click event is triggered)
        this.justChanged = true;
        setTimeout(() => {
            this.justChanged = false;
        }, 100);
    }
    tryScrollIntoView(el) {
        // be defensive here - rather not scroll than to do something wrong
        if (this.buttonRef.current && this.state.isOpen) {
            const els = this.buttonRef.current.parentNode.getElementsByClassName(this.props.menuClassName);
            if (els.length === 1) {
                scrollIntoViewIfNeeded(els[0], el);
            }
        }
    }
    getContext() {
        const { selected, selectedClassName, highlightedClassName } = this.props;
        const { highlighted } = this.state;
        const { onHighlight } = this;
        const registerOption = option => {
            if (this.options.indexOf(option) === -1) {
                this.options.push(option);
                if (this.props.optionToText) {
                    this.optionsAsText.push(this.props.optionToText(option).toLowerCase());
                }
            }
        };
        const deregisterOption = option => {
            const i = this.options.indexOf(option);
            if (i !== -1) {
                this.options.splice(i, 0);
                if (this.props.optionToText) {
                    this.optionsAsText.splice(i, 0);
                }
            }
        };
        const scrollIntoView = el => this.tryScrollIntoView(el);
        return {
            selected,
            highlighted,
            onHighlight,
            onSelect: option => {
                this.skipBlur = true;
                this.selectAndClose(option);
            },
            selectedClassName,
            highlightedClassName,
            registerOption,
            deregisterOption,
            scrollIntoView,
            selectedId: this.ids.selected,
        };
    }
    render() {
        const { className, children, menuComponent, menuClassName, selectComponent, selected, placeholder, buttonClassName, hasError, disabled, ariaLabel, ariaDescription, dataIntercomTarget, } = this.props;
        const { isFocused, isOpen } = this.state;
        const { onFocus, onBlur, onClick } = this;
        return (_jsx(SelectContext.Provider, { value: this.getContext(), children: _jsxs("div", { style: WRAPPER_STYLE, className: className, children: [_jsx(Button, { kind: "PLAIN", "aria-owns": this.ids.menu, "aria-autocomplete": false, "aria-describedby": this.ids.description, "aria-labelledby": this.ids.label, "aria-activedescendant": this.ids.selected, style: FULL_WIDTH, onFocus: onFocus, onBlur: onBlur, onMouseDown: onClick, ref: this.buttonRef, className: buttonClassName, disabled: disabled, "data-intercom-target": dataIntercomTarget, children: selected !== null && selected !== undefined
                            ? createElement(selectComponent, { selected, isOpen, isFocused, hasError, disabled })
                            : createElement(placeholder, { isOpen, isFocused, hasError, disabled }) }), _jsx(SelectMenu, { menuComponent, menuClassName, children, isFocused, isOpen, id: this.ids.menu }), _jsx(AriaLabel, { id: this.ids.label, children: `${ariaLabel}. ${this.getAriaLabel(selected) || 'nothing'} is selected` }), _jsx(AriaLabel, { id: this.ids.description, children: ariaDescription })] }) }));
    }
}
Select.propTypes = propTypes;
Select.defaultProps = defaultProps;
function SelectMenu(props) {
    const { menuComponent, menuClassName, isOpen, isFocused, children, id } = props;
    const style = isOpen ? NO_STYLE : HIDDEN_STYLE;
    return isFocused
        ? createElement(menuComponent, {
            style,
            className: menuClassName,
            id,
        }, children)
        : null;
}
class OptionPlain extends Component {
    constructor() {
        super();
        this.buttonRef = createRef();
    }
    UNSAFE_componentWillMount() {
        this.previousSelectionState = undefined;
        if (this.props.isSearchable) {
            this.props.registerOption(this.props.value);
        }
    }
    componentWillUnmount() {
        if (this.props.isSearchable) {
            this.props.deregisterOption(this.props.value);
        }
    }
    UNSAFE_componentWillReceiveProps() {
        this.previousSelectionState = this.isSelected(this.props);
    }
    shouldComponentUpdate(nextProps) {
        return !!find(Boolean)([
            this.isHighlighted(this.props) !== this.isHighlighted(nextProps),
            this.isSelected(this.props) !== this.isSelected(nextProps),
        ]);
    }
    tryScrollIntoView() {
        const isSelected = this.isSelected(this.props);
        if (!isSelected || this.previousSelectionState === isSelected || !this.buttonRef.current) {
            return;
        }
        this.props.scrollIntoView(this.buttonRef.current);
    }
    isSelected(props) {
        return props.value === props.selected;
    }
    isHighlighted(props) {
        return props.value === props.highlighted;
    }
    render() {
        const { value, onSelect, onHighlight, highlightedClassName, selectedClassName, selectedId, children, className, disabled, } = this.props;
        const isSelected = this.isSelected(this.props);
        const isHighlighted = this.isHighlighted(this.props);
        const cN = classNames(className, {
            [selectedClassName]: isSelected,
            [highlightedClassName]: isHighlighted && !isSelected,
        });
        const trigger = () => onSelect(value);
        this.tryScrollIntoView();
        // we need mouseDown here because of the event order
        // in safari. This also works on mobile - don't just
        // add onTouchStart, because it breaks scrolling in
        // the dropdown
        return (_jsx(Button, { kind: "PLAIN", id: isSelected ? selectedId : null, tabIndex: "-1", className: cN, onMouseDown: trigger, onMouseOver: () => onHighlight(value), onMouseOut: () => onHighlight(value), ref: this.buttonRef, disabled: disabled, children: children }));
    }
}
OptionPlain.defaultProps = {
    isSearchable: true,
};
const Option = props => (_jsx(SelectContext.Consumer, { children: context => _jsx(OptionPlain, Object.assign({}, props, context)) }));
export { Option, Select };
