// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectSimple-select-7ZPze {\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  border: 1px solid #e0dfde;\n  border-radius: 5px;\n  background: #ffffff;\n  padding: 9.5px 28.5px 9.5px 9.5px;\n  font-size: inherit;\n  width: 100%;\n}\n.SelectSimple-select-7ZPze:not(:disabled) {\n  cursor: pointer;\n}\n.SelectSimple-select-7ZPze:hover:not(:disabled) {\n  border-color: #0071d4;\n}\n.SelectSimple-isSmall-eOOaP .SelectSimple-select-7ZPze {\n  padding: 3px 4px;\n  padding-right: 20px;\n  font-size: 13px;\n  height: 28px;\n  --fi-size: 14px;\n}\n.SelectSimple-hasError-cgWvT {\n  box-shadow: 0 0 0 1px #f23037;\n  background: #fff3f3;\n}\n.SelectSimple-container-3ZM2x {\n  position: relative;\n  width: -webkit-fit-content;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n.SelectSimple-icon-1kG7T {\n  position: absolute;\n  right: 9.5px;\n  top: 50%;\n  margin: 0 !important;\n  transform: translateY(-50%);\n  pointer-events: none;\n}\n.SelectSimple-isSmall-eOOaP .SelectSimple-icon-1kG7T {\n  right: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"select": "SelectSimple-select-7ZPze",
	"isSmall": "SelectSimple-isSmall-eOOaP",
	"hasError": "SelectSimple-hasError-cgWvT",
	"container": "SelectSimple-container-3ZM2x",
	"icon": "SelectSimple-icon-1kG7T"
};
module.exports = exports;
