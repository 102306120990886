import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { isFunction, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Children, Component } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import SIZES from '../Modal/sizes';
import SlideShow from '../SlideShow';
import styles from './style.m.less';
const propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func,
    size: PropTypes.oneOf(Object.keys(SIZES)),
    height: PropTypes.number.isRequired, // for now
    backgroundImage: PropTypes.string,
    backgroundSize: PropTypes.string,
    nextLabel: PropTypes.any,
    doneLabel: PropTypes.any,
    page: PropTypes.number,
    onPageMove: PropTypes.func,
};
const DEFAULT_SIZE = 'LG_WIDTH';
export function MultiModalPage({ children }) {
    return _jsx("div", { children: children });
}
export class MultiModal extends Component {
    constructor(props) {
        super(props);
        const { page } = props;
        this.state = {
            visiblePage: isUndefined(page) ? 0 : page,
        };
        this.goToNext = this.goToNext.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { page } = nextProps;
        if (isUndefined(page) || page === this.props.page) {
            return;
        }
        this.goToPage(page);
    }
    goToNext() {
        const { children } = this.props;
        const newI = this.state.visiblePage + 1;
        if (Children.count(children) <= newI) {
            return;
        }
        this.goToPage(newI);
    }
    goToPage(i) {
        this.setState({ visiblePage: i });
        const { onPageMove } = this.props;
        if (isFunction(onPageMove)) {
            onPageMove(i);
        }
    }
    render() {
        const { children, title, isOpen, onClose, height, size, backgroundImage, backgroundSize, nextLabel, doneLabel, } = this.props;
        const { visiblePage } = this.state;
        const { goToPage, goToNext } = this;
        const onDone = this.props.onDone || onClose;
        const pageCount = Children.count(children);
        const isLastPage = visiblePage === pageCount - 1;
        const modalProps = {
            title,
            isOpen,
            onClose,
            backgroundImage,
            backgroundSize,
            size: size || DEFAULT_SIZE,
        };
        const sliderProps = {
            page: visiblePage,
            height,
        };
        const dotsProps = { visiblePage, pageCount, goToPage };
        const buttonProps = isLastPage
            ? {
                children: doneLabel || 'Done',
                onClick: onDone,
            }
            : {
                children: nextLabel || 'Next',
                onClick: goToNext,
            };
        return (_jsxs(Modal, Object.assign({}, modalProps, { children: [_jsx(SlideShow, Object.assign({}, sliderProps, { children: children })), _jsxs("div", { className: styles.footer, children: [_jsx(Dots, Object.assign({}, dotsProps)), _jsx(Button, Object.assign({ kind: "PRIMARY" }, buttonProps))] })] })));
    }
}
function Dots({ visiblePage, goToPage, pageCount }) {
    const dots = [];
    for (let i = 0; i < pageCount; i++) {
        dots.push(_jsx(Button, { kind: "PLAIN", onClick: () => goToPage(i), className: classNames({
                [styles.dot]: true,
                [styles.selected]: visiblePage === i,
            }), "aria-label": `Go to step ${i + 1}` }, i));
    }
    return _jsx("div", { className: styles.dots, children: dots });
}
MultiModal.propTypes = propTypes;
