// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Autocomplete-results_container-3sSFJ {\n  position: relative;\n}\n.Autocomplete-results-2KMJF {\n  position: absolute;\n  top: 5px;\n  z-index: 700;\n  background: #ffffff;\n  border: 1px solid #e0dfde;\n  border-radius: 10px;\n  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05), 1px 1px 4px rgba(0, 0, 0, 0.04);\n  overflow: hidden;\n}\n.Autocomplete-resultGroupHeading-1XNFL {\n  color: #7a7a79;\n  font-weight: 500;\n  font-size: 11px;\n  text-transform: uppercase;\n  padding: 10px 10px 5px;\n  margin: 0;\n  --fi-stroke: var(--fi-stroke-m);\n}\n.Autocomplete-results_empty-1XrHD {\n  border-color: transparent;\n  border-width: 0;\n}\n.Autocomplete-results_inner-2n6aj,\n.Autocomplete-item-1of2M,\n.Autocomplete-results-2KMJF {\n  width: 100%;\n}\n.Autocomplete-more_items-2cBV2 {\n  cursor: default;\n  padding: 5px;\n  text-align: center;\n  font-size: 13px;\n  font-style: italic;\n  color: #a3a2a1;\n  --fi-size: 14px;\n}\n.Autocomplete-nothing_found-2Rlay {\n  cursor: default;\n  display: flex;\n  padding: 10px;\n  align-items: center;\n  height: 100%;\n  color: #a3a2a1;\n  min-height: 30px;\n}\n.Autocomplete-choice-31tOl {\n  width: 100%;\n  box-sizing: border-box;\n  color: #1a1a1a;\n  cursor: pointer;\n  display: block;\n  padding: 8px 10px;\n  overflow: hidden;\n}\n.Autocomplete-choice-31tOl.Autocomplete-isHighlighted-3vPFX {\n  background: #edeceb;\n}\n.Autocomplete-inputContainer-10RZX {\n  position: relative;\n}\n.Autocomplete-arrow-dww_R {\n  position: absolute;\n  top: 25%;\n  right: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"results_container": "Autocomplete-results_container-3sSFJ",
	"results": "Autocomplete-results-2KMJF",
	"resultGroupHeading": "Autocomplete-resultGroupHeading-1XNFL",
	"results_empty": "Autocomplete-results_empty-1XrHD",
	"results_inner": "Autocomplete-results_inner-2n6aj",
	"item": "Autocomplete-item-1of2M",
	"more_items": "Autocomplete-more_items-2cBV2",
	"nothing_found": "Autocomplete-nothing_found-2Rlay",
	"choice": "Autocomplete-choice-31tOl",
	"isHighlighted": "Autocomplete-isHighlighted-3vPFX",
	"inputContainer": "Autocomplete-inputContainer-10RZX",
	"arrow": "Autocomplete-arrow-dww_R"
};
module.exports = exports;
