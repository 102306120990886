import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { get, noop, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Button, { KINDS } from '../../components/Button';
import { DeprecatedButtonContainer } from '../../components/ButtonContainer';
import Modal from '../../components/Modal';
const propTypes = {
    title: PropTypes.string,
    body: PropTypes.node,
    confirmKind: PropTypes.oneOf(KINDS),
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    isConfirmationActive: PropTypes.bool,
    onClose: PropTypes.func,
    size: PropTypes.string,
    autoFocusConfirmButton: PropTypes.bool,
};
const defaultProps = {
    title: 'Please confirm',
    confirmKind: 'PRIMARY',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    isConfirmationActive: true,
    onClose: noop,
    size: 'MD_WIDTH',
    autoFocusConfirmButton: true,
};
export default function withConfirmModal(WrappedComponent, handlerName = 'onClick') {
    class ComponentWithConfirm extends Component {
        constructor(props) {
            super(props);
            this.state = { modalOpen: false };
            this.onClose = this.onClose.bind(this);
            this.onOpen = this.onOpen.bind(this);
            this.originalHandler = get(this.props, handlerName, noop);
            this.onConfirm = this.onConfirm.bind(this);
            this.isConfirmationActive = get(this.props, 'isConfirmationActive', true);
        }
        closeModal() {
            this.setState({ modalOpen: false });
        }
        onClose() {
            this.closeModal();
            this.props.onClose();
        }
        onOpen() {
            if (this.isConfirmationActive) {
                this.setState({ modalOpen: true });
            }
            else {
                this.onConfirm();
            }
        }
        onConfirm() {
            this.setState({ modalOpen: false });
            this.originalHandler();
        }
        render() {
            const { title, body, cancelLabel, confirmLabel, confirmKind, size, autoFocusConfirmButton } = this.props;
            const componentProps = Object.assign(Object.assign({}, omit(this.props, ['confirmLabel', 'cancelLabel', 'isConfirmationActive'])), { onClick: this.onOpen });
            const modalProps = {
                noCloseIcon: true,
                title,
                isOpen: this.state.modalOpen,
                onClose: this.onClose,
                size,
            };
            return (_jsxs(WrappedComponent, Object.assign({}, componentProps, { children: [this.props.children, _jsxs(Modal, Object.assign({}, modalProps, { children: [body, _jsxs(DeprecatedButtonContainer, { children: [_jsx(Button, { onClick: this.onClose, children: cancelLabel }), _jsx(Button, { kind: confirmKind, onClick: this.onConfirm, autoFocus: autoFocusConfirmButton, children: confirmLabel })] })] }))] })));
        }
    }
    ComponentWithConfirm.propTypes = propTypes;
    ComponentWithConfirm.defaultProps = defaultProps;
    return ComponentWithConfirm;
}
