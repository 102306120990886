import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PLANS } from '../../../../features/Billing/plans';
import { goToCheckout } from '../../../../services/GoTo';
import { trackEvent } from '../../../../services/Tracking';
import Button from '../../../Button';
import { CardPlan } from './CardPlan';
import styles from './styles.m.less';
const UpgradePlanContent = ({ currentPlan }) => {
    const handleUpgradeClick = () => {
        trackEvent({
            category: 'upselling',
            action: 'clicked upgrade',
            label: 'clicked upgrade your plan in modal',
        });
        goToCheckout();
    };
    return (_jsxs("div", { className: styles.container, children: [_jsx("p", { className: styles.subTitle, children: "Upgrade to unlock access" }), _jsx("div", { className: styles.cardsContainer, children: PLANS.map(plan => {
                    return (_jsx(CardPlan, { plan: plan, className: currentPlan === plan.id ? styles.currentPlan : styles.upgradePlan }, plan.id));
                }) }), _jsxs("div", { className: styles.buttonContainer, children: [_jsx(Button, { onClick: handleUpgradeClick, kind: "PRIMARY", children: "Upgrade your plan" }), _jsx("small", { children: "Click the button to learn more, you won't be charged yet" })] })] }));
};
export default UpgradePlanContent;
