// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".GridRadio-gridRadio-2HQde {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));\n  flex-direction: column;\n  justify-content: center;\n  gap: 20px;\n  font-size: 15px;\n  --fi-size: 18px;\n}\n.GridRadio-gridRadio-2HQde input {\n  border: none;\n  position: absolute;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n}\n.GridRadio-gridRadio-2HQde input:checked + label {\n  background: #f5f4f3;\n  border-color: #0071d4;\n}\n.GridRadio-gridRadio-2HQde input:focus-visible + label:not([disabled]) {\n  box-shadow: 0 0 0 1px #0071d4;\n}\n.GridRadio-gridRadio-2HQde input:not(:checked) + label:not([disabled]):hover {\n  background-color: #f5f4f3;\n}\n.GridRadio-gridRadio-2HQde label {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: #ffffff;\n  flex: 1 auto;\n  text-align: center;\n  padding: 10px;\n  border-radius: 5px;\n  border: 1px solid #e0dfde;\n  cursor: pointer;\n}\n.GridRadio-gridRadio-2HQde.GridRadio-small-3TbrB {\n  height: 28px;\n  padding: 2px;\n}\n.GridRadio-gridRadio-2HQde.GridRadio-small-3TbrB label {\n  height: 22px;\n}\n.GridRadio-regularLabel-2quSs {\n  font-size: 15px;\n  --fi-size: 18px;\n}\n.GridRadio-smallLabel-1QOtr {\n  font-size: 13px;\n  --fi-size: 14px;\n}\n.GridRadio-image-2Wu0s {\n  margin-bottom: 5px;\n  outline: none;\n}\n.GridRadio-image-2Wu0s,\n.GridRadio-image-2Wu0s svg {\n  width: 45px;\n  height: 45px;\n  color: #4d4d4c;\n}\n", ""]);
// Exports
exports.locals = {
	"gridRadio": "GridRadio-gridRadio-2HQde",
	"small": "GridRadio-small-3TbrB",
	"regularLabel": "GridRadio-regularLabel-2quSs",
	"smallLabel": "GridRadio-smallLabel-1QOtr",
	"image": "GridRadio-image-2Wu0s"
};
module.exports = exports;
