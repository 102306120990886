// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".autocompletion-container-2WyqG {\n  background: #ffffff;\n  border: 1px solid #e0dfde;\n  border-radius: 10px;\n  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05), 1px 1px 4px rgba(0, 0, 0, 0.04);\n  transition: border-color 150ms ease-in;\n  white-space: nowrap;\n}\n.autocompletion-item-1vlv2 {\n  display: block;\n  width: 100%;\n  max-width: 400px;\n  padding: 5px;\n}\n.autocompletion-item-1vlv2.autocompletion-highlighted-1FYuG {\n  background: #edeceb;\n  cursor: pointer;\n}\n.autocompletion-item-1vlv2 img {\n  height: 20px;\n  width: 20px;\n  margin-right: 5px;\n  border-radius: 50%;\n  overflow: hidden;\n  vertical-align: text-bottom;\n}\n.autocompletion-moreItems-2PDcf {\n  cursor: default;\n  padding: 6px;\n  text-align: center;\n  font-size: 13px;\n  font-style: italic;\n  color: #a3a2a1;\n  --fi-size: 14px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "autocompletion-container-2WyqG",
	"item": "autocompletion-item-1vlv2",
	"highlighted": "autocompletion-highlighted-1FYuG",
	"moreItems": "autocompletion-moreItems-2PDcf"
};
module.exports = exports;
