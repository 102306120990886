import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import c from 'classnames';
import { IconChevronDown, IconX } from 'featherico';
import { getOr } from 'lodash/fp';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { useAppContext } from '../../../../hooks/useAppContext';
import { AriaAnnouncer } from '../../../AriaHelper';
import ClickOutside from '../../../ClickOutside';
import { InputButtonIcon } from '../../../Input/InputButton';
import { InputIcon } from '../../../Input/InputIcon';
import getIsDateInRangeError from '../../BasePicker/Validators/isDateInRange';
import Popup from '../Popup';
import styles from './styles.m.less';
export const KINDS_CLASSMAP = {
    DEFAULT: c(styles.kindDefault),
    TITLE: c(styles.kindTitle),
};
export const KINDS = Object.keys(KINDS_CLASSMAP);
export default function Picker(props) {
    const inputRef = useRef(null);
    const isInteractive = !props.disabled && !props.readOnly;
    const [isFocussed, setIsFocussed] = useState(false);
    const [skipBlur, setSkipBlur] = useState(false);
    const [isPickerOpen, setPickerOpen] = useState(false);
    const closePicker = () => setPickerOpen(false);
    const [inputValue, setInputValue, inputError] = useInput(props.date, props.onChange, props.validRange, props.isClearable);
    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            closePicker();
        }
    };
    const handleChange = (date) => {
        props.onChange(date);
        closePicker();
    };
    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setIsFocussed(true);
    };
    const handleBlur = () => {
        setIsFocussed(false);
        if (skipBlur) {
            setSkipBlur(false);
            return;
        }
        closePicker();
    };
    const handleFocus = (e) => {
        setPickerOpen(true);
        setIsFocussed(true);
        props.onFocus(e);
    };
    const handlePopupMouseDown = () => setSkipBlur(true);
    const prefix = props.formatPrefix && props.formatPrefix(props.date);
    const showClearButton = props.showIcon && props.isClearable && inputValue !== '' && !props.disabled && !props.readOnly;
    const clearValue = (event) => {
        event.stopPropagation();
        setInputValue('');
        closePicker();
    };
    return (_jsx("div", { className: c(KINDS_CLASSMAP[props.kind], styles.wrapper), onBlur: handleBlur, children: _jsx(ClickOutside, { onClickOutside: closePicker, enabled: isPickerOpen, children: _jsxs(Manager, { children: [_jsx(Reference, { children: ({ ref }) => (_jsxs(_Fragment, { children: [_jsxs("div", { ref: ref, className: c(styles.box, {
                                        [styles.withIcon]: props.showIcon,
                                        [styles.invalid]: inputError || props.hasError,
                                        [styles.focus]: isFocussed,
                                        [styles.disabled]: props.disabled,
                                        [styles.readOnly]: props.readOnly,
                                        [styles.isSmall]: props.isSmall,
                                    }), onClick: isInteractive ? handleClick : undefined, role: "textbox", children: [prefix, _jsx("input", { type: "text", value: inputValue || '', maxLength: 10, className: styles.input, ref: inputRef, onChange: e => setInputValue(e.target.value), onFocus: isInteractive ? handleFocus : undefined, onKeyDown: isInteractive ? handleKeyDown : undefined, placeholder: props.placeholderText, "aria-label": props.placeholderText, id: props.id, disabled: props.disabled, readOnly: props.readOnly, "data-intercom-target": props.dataIntercomTarget }), props.showIcon && (_jsx(InputIcon, { small: true, className: c(styles.chevron, {
                                                [styles.hideOnHover]: showClearButton,
                                                [styles.disabled]: props.disabled,
                                            }), "aria-hidden": true, children: _jsx(IconChevronDown, {}) }))] }), showClearButton && (_jsx(InputButtonIcon, { className: styles.clearButton, onClick: isInteractive ? clearValue : undefined, size: props.isSmall ? 'SMALL' : props.kind, "aria-label": "Clear Date", children: _jsx(IconX, {}) }))] })) }), isPickerOpen && (_jsx(Popper, { placement: "bottom-start", children: ({ ref, style }) => (_jsx("div", { ref: ref, style: style, className: styles.popup, children: _jsx(Popup, { date: props.date, onChange: handleChange, errorMessage: inputError, onMouseDown: handlePopupMouseDown, highlightedDates: props.highlightedDates, validRange: props.validRange }) })) })), inputError && _jsx(AriaAnnouncer, { ariaRole: "alert", children: inputError })] }) }) }));
}
function sameDate(a, b) {
    if (!a && !b)
        return true;
    if (!!a && !!b)
        return a.isSame(b, 'day');
    return false;
}
function useInput(date, onChange, validRange, isClearable) {
    const appContext = useAppContext();
    const dateFormat = getOr(moment.HTML5_FMT.DATE, 'user.company.companySettings.dateFormatCoarse', appContext);
    const [inputValue, setInputValue] = useState(date ? date.format(dateFormat) : '');
    const [inputError, setInputError] = useState(null);
    useEffect(() => {
        if (!sameDate(date, inputValue ? moment(inputValue, dateFormat, true) : undefined)) {
            setInputValue(date ? date.format(dateFormat) : '');
        }
        setInputError(null);
    }, [date, dateFormat]); // eslint-disable-line react-hooks/exhaustive-deps
    const handleInputValue = useCallback((newInputValue) => {
        if (newInputValue === '' && isClearable) {
            setInputValue('');
            setInputError(null);
            onChange(undefined);
            return;
        }
        setInputValue(newInputValue);
        const newDate = moment(newInputValue, dateFormat, true);
        if (!newDate.isValid()) {
            return setInputError('Please check the entered date format');
        }
        const dateInRangeError = getIsDateInRangeError(newDate, validRange, dateFormat);
        if (dateInRangeError) {
            return setInputError(dateInRangeError);
        }
        setInputError(null);
        onChange(newDate);
    }, [onChange, dateFormat, validRange, isClearable]);
    return [inputValue, handleInputValue, inputError];
}
