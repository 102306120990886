import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
export { GridItem } from './GridItem';
Grid.propTypes = {
    children: PropTypes.node,
    noPadding: PropTypes.bool,
    verticalAlign: PropTypes.oneOf(['top', 'center', 'bottom', 'baseline']),
    horizontalAlign: PropTypes.oneOf(['right', 'center']),
};
Grid.defaultProps = {
    noPadding: false,
};
/**
 * @deprecated Please leverage the native CSS grid possibities.
 */
export function Grid({ children, verticalAlign, horizontalAlign, noPadding }) {
    const valign = verticalAlign ? `valign--${verticalAlign}` : null;
    const align = horizontalAlign ? `align--${horizontalAlign}` : null;
    const noGutters = noPadding ? 'grid--no_gutters' : null;
    return _jsx("div", { className: classNames('grid', valign, align, noGutters), children: children });
}
