// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UpgradePlanContent-container-qy2mG,\n.UpgradePlanContent-buttonContainer-1xpp5 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.UpgradePlanContent-subTitle-3Ul5i {\n  font-weight: 600;\n  font-size: 18px;\n  --fi-size: 20px;\n  --fi-stroke: var(--fi-stroke-l);\n}\n.UpgradePlanContent-cardsContainer-2j5Re {\n  display: flex;\n  gap: 10px;\n}\n@media (max-width: 500px) {\n  .UpgradePlanContent-cardsContainer-2j5Re {\n    flex-direction: column;\n  }\n}\n.UpgradePlanContent-planContainer-2CkS4 {\n  border: 1px solid #e0dfde;\n  border-radius: 5px;\n  padding: 10px;\n  gap: 10px;\n}\n.UpgradePlanContent-currentPlan-3dtv5 {\n  border-color: #ffcc00;\n  border-width: 2px;\n  padding-top: -20px;\n  position: relative;\n}\n.UpgradePlanContent-currentPlan-3dtv5:before {\n  content: 'Current Plan';\n  background-color: #ffcc00;\n  color: #ffffff;\n  border-radius: 5px;\n  position: absolute;\n  top: -10px;\n  left: 30%;\n  text-align: center;\n}\n@media (max-width: 500px) {\n  .UpgradePlanContent-currentPlan-3dtv5:before {\n    left: 37%;\n  }\n}\n.UpgradePlanContent-buttonContainer-1xpp5 {\n  margin-top: 20px;\n  width: 100%;\n}\n.UpgradePlanContent-buttonContainer-1xpp5 a {\n  width: 100%;\n}\n.UpgradePlanContent-priceContainer-9s3e0 {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  margin-bottom: 20px;\n  gap: 10px;\n}\n.UpgradePlanContent-price-2_29o {\n  font-size: 50px;\n  line-height: 1;\n  margin: 0;\n}\n.UpgradePlanContent-priceDescription-1JUjJ {\n  font-size: 11px;\n}\n.UpgradePlanContent-priceDescription-1JUjJ p {\n  font-size: 11px;\n  margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "UpgradePlanContent-container-qy2mG",
	"buttonContainer": "UpgradePlanContent-buttonContainer-1xpp5",
	"subTitle": "UpgradePlanContent-subTitle-3Ul5i",
	"cardsContainer": "UpgradePlanContent-cardsContainer-2j5Re",
	"planContainer": "UpgradePlanContent-planContainer-2CkS4",
	"currentPlan": "UpgradePlanContent-currentPlan-3dtv5",
	"priceContainer": "UpgradePlanContent-priceContainer-9s3e0",
	"price": "UpgradePlanContent-price-2_29o",
	"priceDescription": "UpgradePlanContent-priceDescription-1JUjJ"
};
module.exports = exports;
