// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MultiModal-footer-P_e5i {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.MultiModal-dots-1ODbp {\n  display: flex;\n  gap: 10px;\n}\n.MultiModal-dot-1oFGp {\n  flex: 0 0 20px;\n  height: 20px;\n  border: 3px solid #ffffff;\n  outline: 1px solid #e0dfde;\n  background-color: #ffffff;\n  border-radius: 50%;\n  padding: 5px;\n}\n.MultiModal-dot-1oFGp:hover {\n  background-color: #edeceb;\n}\n.MultiModal-dot-1oFGp:focus-visible {\n  box-shadow: 0 0 0 2px #0071d4;\n}\n.MultiModal-dot-1oFGp.MultiModal-selected-2k7F8 {\n  background-color: #0071d4;\n}\n", ""]);
// Exports
exports.locals = {
	"footer": "MultiModal-footer-P_e5i",
	"dots": "MultiModal-dots-1ODbp",
	"dot": "MultiModal-dot-1oFGp",
	"selected": "MultiModal-selected-2k7F8"
};
module.exports = exports;
